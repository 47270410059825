import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { ClassNames } from '@emotion/react';

const Navbar = () => {
    const { isConnected } = useWeb3ModalAccount();
    const [connected, setConnected] = useState(false); // Assuming initially not connected

    const connectWallet = () => {
        // Your logic to connect wallet
        setConnected(true); // Set connected to true once wallet is connected
    };

    return (
        <Flex justify="center" align="center" mb="10px" py="25px" position="contain" top="0" zIndex="contain">
        

            {isConnected ? (
             <w3m-account-button balance="show"  />
            ) : (
                <w3m-connect-button size="sm" label="Connect Wallet" onClick={connectWallet} />
            )}

            <w3m-network-button />
        </Flex>
    );
};

export default Navbar;
