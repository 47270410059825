import { Box, Flex, Grid, Image, Card, CardBody, CardFooter,Stack, Text, Button, CardHeader, Center } from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';

import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import { ethers, formatEther } from 'ethers';

import { BrowserProvider, Contract, formatUnits, WebSocketProvider, parseUnits, parseEther } from 'ethers'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import BigNumber from 'bignumber.js';
import LionToken from '../artifacts/contracts/LionToken.sol/LionToken.json';
import LionNft from '../artifacts/contracts/LionNFT.sol/LionNFT.json';
import LionPair from '../artifacts/contracts/LionPair.sol/LionPair.json';
import Popup from 'reactjs-popup';

import Swal from 'sweetalert2';
import '../Styles/Home.css';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
import bottomImg from '../Images/separator-top copy.svg';
import topImg from '../Images/separator-bottom copy.svg';




const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};


const nftContractAddress = '0xA9FB0025F2D474890dfFE70DB24aA027310489CB';
const shieldAddress = '0x46c0F8259C4E4D50320124E52f3040cb9e4d04c7';
const shieldStakeAddress = '0x7f86a3b236f8f5e730B4beA9B67F89b190E9F1bB';


const NFTHead = () => {

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const [showCompound, setShowCompound] = useState(false);
  const [showUnstake, setShowUnstake] = useState(false);
  const [showStake, setShowStake] = useState(false);
  const [showETHStake, setShowETHStake] = useState(false);

  const [showBuyLotto, setShowBuyLotto] = useState(false);

  const [showRevBuyLotto, setShowRevBuyLotto] = useState(false);
  const [showEthBuyLotto, setShowEthBuyLotto] = useState(false);
  const [RevTicketDeposit, setDepositChangeRevTicket] = useState();
  const [EthTicketDeposit, setDepositChangeETHTicket] = useState();


  const [showClaimLotto, setShowClaimLotto] = useState(false);
  const [showHistoryLotto, setShowHistoryLotto] = useState(false);
  const [showBoughtLotto, setShowBoughtLotto] = useState(false);


  const [showTokenStake, setShowTokenStake] = useState(false);
  const [toggleValue, setToggleValue] = useState('');
  const [toggleValueLotto, setToggleValueLotto] = useState('');
  const [toggleStakeValue, setToggleStakeValue] = useState('');
  const [ethStakeDeposit, setDepositChangeStakeETH] = useState();
  const [TokenStakeDeposit, setDepositChangeStakeToken] = useState();
  const [CompoundETHDeposit, setDepositChangeCompoundETH] = useState();
  const [CompoundSlippageDeposit, setDepositChangeCompoundSlippage] = useState();
  const [stakeETHSlippageDeposit, setDepositChangeStakeETHSlippage] = useState();
  const [LottoSearch, setLottoSearch] = useState();
  const [unstakeDeposit, setDepositChangeUnstake] = useState();
  const [stakedAmt, setStakedAmt] = useState();
  const [stakeunlock, setStakeUnlock] = useState();
  const [compoundedtokens, setCompoundAmt] = useState();
  const [spendableeth, setSpendableEth] = useState();
  const [userTokenBal, setUserTokenBal] = useState();
  const [lottoDiscount, setUserLottoDiscount] = useState();
  const [userLottoPrice, setUserLottoPrice] = useState();
  const [userLottoFree, setUserLottoFree] = useState();
  const [ShowFreeLottoBtn, setUserLottoFreeBtn] = useState(false);
  const [ShowTotalLottoAmt, setTotalLottoAmt] = useState();
  const [campaignData, setCampaignData] = useState([]);




  const [searchLottoAmt, setLottoSearchAmt] = useState();
  const [searchLottoFirst, setLottoSearchFirst] = useState();
  const [searchLottoSecond, setLottoSearchSecond] = useState();
  const [searchLottoThird, setLottoSearchThird] = useState();
  const [searchLottoFirstAmt, setLottoSearchFirstAmt] = useState();
  const [searchLottoSecondAmt, setLottoSearchSecondAmt] = useState();
  const [searchLottoThirdAmt, setLottoSearchThirdAmt] = useState();
  const [stakeRate, setUserStakeRate] = useState();
  const [withdrawlamt, setWithdrawlAmt] = useState();
  const [totalClaimed, setTotalClaimed] = useState();
  const [stakedPercent, setStakedPercent] = useState();
  const [totalLottoPaid, setTotalLottoPaid] = useState();




  async function fetchStakedPercent() {
    if(address){
    let ethersProvider;
    ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
  
    const contract = new Contract(nftContractAddress, LionNft.abi, signer);
    try {
        const call = await contract.stakerVaults(address);
        let stake = await call;
        let amount = stake[0].toString();

        const total = await contract.tokensStaked();
        let totalstaked = await total.toString();

        let stakedpercent = (amount/totalstaked)*100;
        let stakedpercentvar = stakedpercent.toFixed(2);

        if(amount == 0){
          setStakedPercent("Not Staked");
        }
        else{

        setStakedPercent(stakedpercentvar + "%");
        }

    } catch (err) {
        console.log(err.message);
    }
  }
  
  else{
    setStakedPercent("Not Connected");
  
  }
  }


  async function fetchTotalLottoPaid() {
    if(address){
    let ethersProvider;
    ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
  
    const contract = new Contract(nftContractAddress, LionNft.abi, signer);
    try {
        const call = await contract.totalLottoPaid();
        let stake = await call;
        let amount = stake.toString();
        let ethfirst = formatUnits(amount, 18);
        let newstruncatedBalances = Math.floor(parseFloat(ethfirst) * 10000) / 10000; 
        setTotalLottoPaid(newstruncatedBalances + " ETH");


    } catch (err) {
        console.log(err.message);
    }
  }
  
  else{
    setTotalLottoPaid("Not Connected");
  
  }
  }












  async function fetchLottoSearch() {
    let ethersProvider;
    ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
  
    const contract = new Contract(nftContractAddress, LionNft.abi, signer);
    try {
        const call = await contract.LottosHistory(LottoSearch);
        
        let amount = call[1].toString();
        let ethtotal = formatUnits(amount, 18);
        let newstruncatedBalances = Math.floor(parseFloat(ethtotal) * 10000) / 10000; 
        setLottoSearchAmt("Total " + newstruncatedBalances);
        let first = call[2];
        setLottoSearchFirst(abbreviateAddress(first));
        let second = call[3];
        setLottoSearchSecond(abbreviateAddress(second));
        let third = call[4];
        setLottoSearchThird(abbreviateAddress(third));
        let firstAmt = call[5].toString();
        let ethfirst = formatUnits(firstAmt, 18);
        let onewstruncatedBalances = Math.floor(parseFloat(ethfirst) * 10000) / 10000; 
        setLottoSearchFirstAmt(onewstruncatedBalances + "ETH");
        let secondAmt = call[6].toString();
        let ethsecond = formatUnits(secondAmt, 18);
        let tnewstruncatedBalances = Math.floor(parseFloat(ethsecond) * 10000) / 10000; 
        setLottoSearchSecondAmt(tnewstruncatedBalances + "ETH");
        let thirdAmt = call[7].toString();
        let eththird = formatUnits(thirdAmt, 18);
        let thnewstruncatedBalances = Math.floor(parseFloat(eththird) * 10000) / 10000; 
        setLottoSearchThirdAmt(thnewstruncatedBalances + "ETH");
    
    } catch (err) {
        console.log(err.message);
    }
  }

  function abbreviateAddress(address) {
    if (!address) return '';

    return address.substring(0, 6) + '...' + address.substring(address.length - 5);
}




async function fetchTotalClaimed() {
  if(address){
  let ethersProvider;
  ethersProvider = new BrowserProvider(walletProvider)
  const signer = await ethersProvider.getSigner()

  const contract = new Contract(nftContractAddress, LionNft.abi, signer);
  try {
      const call = await contract.stakerVaults(address);
      let stake = await call;
      let amount = stake[7].toString();
      let ethvar = formatUnits(amount, 18);
      console.log(ethvar + "Staked Amount");

      setTotalClaimed(ethvar);

     
      console.log("fetchStaker");
  } catch (err) {
      console.log(err.message);
  }
}

else{
  setTotalClaimed("Not Connected");

}
}




























  async function fetchStakerInfo() {
    if(address){
    let ethersProvider;
    ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()

    const contract = new Contract(nftContractAddress, LionNft.abi, signer);
    try {
        const call = await contract.stakerVaults(address);
        let stake = await call;
        let amount = stake[0].toString();
        let ethvar = formatUnits(amount, 18);
        let truncatedBalances = Math.floor(parseFloat(ethvar) * 10000) / 10000; 
        
        console.log(ethvar + "Staked Amount");

        setStakedAmt(truncatedBalances);

        let unlockTime = stake[4].toString();
        if(unlockTime > 0) {
        let unixTimestamp = unlockTime;
        const timestampInMilliseconds = unixTimestamp * 1000;
        
        // Create a new Date object with the timestamp in milliseconds
        const dateObject = new Date(timestampInMilliseconds);
        
        // Format the date and time using toLocaleString with options
        const options = { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: '2-digit' };
        const formattedDateTime = dateObject.toLocaleString('en-US', options);
        
        setStakeUnlock(formattedDateTime);
        } else {
          setStakeUnlock("No Open Stake");
        }

        let compound = stake[9].toString();
        let compvar = formatUnits(compound, 18);
        let newtruncatedBalances = Math.floor(parseFloat(compvar) * 10000) / 10000; 
        setCompoundAmt(newtruncatedBalances);

        const Newcall = await contract.viewPendingEth(address);
        let avail = await Newcall;
        let availamount = parseFloat(avail.toString()); // Convert availamount to a number
        let spendable = ((parseFloat(stake[8])) + availamount).toString();
        let spend = formatUnits(spendable, 18);
        let newstruncatedBalances = Math.floor(parseFloat(spend) * 10000) / 10000; 
       
        setSpendableEth(newstruncatedBalances + " ETH");
     
        console.log("fetchStaker");
    } catch (err) {
        console.log(err.message);
    }
  }
  else{
    setStakedAmt("Not Connected");
    setStakeUnlock("Not Connected");
    setCompoundAmt("Not Connected");
    setSpendableEth("Not Connected");
  
  }
}



async function fetchUserBal() {
  let ethersProvider;
  ethersProvider = new BrowserProvider(walletProvider)
  const signer = await ethersProvider.getSigner()

  const contract = new Contract(shieldAddress, LionToken.abi, signer);
  try {
      const call = await contract.balanceOf(address);
      let stake = await call;
      let amount = stake.toString();
      let ethtokens = formatUnits(amount, 18);
      
      let truncatedBalances = Math.floor(parseFloat(ethtokens) * 10000) / 10000; // Keep 4 decimal places without rounding up
      console.log(ethtokens + " Staked Amount");
      setUserTokenBal(truncatedBalances);
  
  } catch (err) {
      console.log(err.message);
  }
}

async function fetchLottoAmt() {
  if(address){
  let ethersProvider;
  ethersProvider = new BrowserProvider(walletProvider)
  const signer = await ethersProvider.getSigner()

  const contract = new Contract(nftContractAddress, LionNft.abi, signer);
  try {
      const call = await contract.lottoTotalAmt();
      let stake = await call;
      let amount = stake.toString();
      let ethtokens = formatUnits(amount, 18);
      let fixxed = parseFloat(ethtokens).toFixed(4);
      console.log(ethtokens + "Staked Amount");
      setTotalLottoAmt(fixxed + " ETH");
  
  } catch (err) {
      console.log(err.message);
  }
}
else{
  setTotalLottoAmt("Not Connected");


}
}

async function fetchLottoBought() {
  if(address){
  let ethersProvider;
  ethersProvider = new BrowserProvider(walletProvider)
  const signer = await ethersProvider.getSigner()

  const contract = new Contract(nftContractAddress, LionNft.abi, signer);
  try {
      const call = await contract.weeklyBoughtTickets();
      let stake = await call;
      let amount = stake.toString();

      setShowBoughtLotto(amount + " Tickets");
  
  } catch (err) {
      console.log(err.message);
  }
}
else{
  setShowBoughtLotto("Not Connected");


}
}



async function fetchUserStakePercent() {
  if(address){
  let ethersProvider;
  ethersProvider = new BrowserProvider(walletProvider)
  const signer = await ethersProvider.getSigner()

  const contract = new Contract(nftContractAddress, LionNft.abi, signer);
  try {
      const call = await contract.viewRewardsRate(address);
    
      let amount = call.toString();
     
      setUserStakeRate(amount + "%");
    } catch (err) {
      console.log(err.message);
  }
  }
  else{
    setUserStakeRate("Not Connected");
  
  }


}






async function fetchUserLottoDiscount() {
  let ethersProvider;
  ethersProvider = new BrowserProvider(walletProvider)
  const signer = await ethersProvider.getSigner()

  const contract = new Contract(nftContractAddress, LionNft.abi, signer);
  try {
      const call = await contract.discount(address);
    
      let amount = call.toString();
      console.log(amount + "Discount");
      setUserLottoDiscount(amount);
  
  } catch (err) {
      console.log(err.message);
  }
}


async function fetchUserLottoFree() {
  let ethersProvider;
  ethersProvider = new BrowserProvider(walletProvider)
  const signer = await ethersProvider.getSigner()

  const contract = new Contract(nftContractAddress, LionNft.abi, signer);
  try {
      const call = await contract.canClaimFreeTicket(address);
    
      let amount = call.toString();
      if(amount == "true"){
        amount = "Eligible For Free Ticket";
        setUserLottoFreeBtn(true);
      } else {
        amount = "Ineligible For Free Ticket";
      }

      console.log(amount + "Can Claim Free");
      setUserLottoFree(amount);
  
  } catch (err) {
      console.log(err.message);
  }
}



async function fetchUserLottoPrice() {
  let ethersProvider;
  ethersProvider = new BrowserProvider(walletProvider)
  const signer = await ethersProvider.getSigner()

  const contract = new Contract(nftContractAddress, LionNft.abi, signer);
  try {
      const discount = await contract.discount(address);
    
      let amount = discount.toString();

      const base = await contract.baseTicketPrice();

      let baseprice = base.toString();

      let userPrice;

      if(amount !== "0") {
        let DisAmt = (baseprice * amount) / 100;
        userPrice = baseprice - DisAmt;

      } else {
        userPrice = baseprice;
      }

      const etheramt = formatEther(userPrice);

      setUserLottoPrice(etheramt);
    
  
  } catch (err) {
      console.log(err.message);
  }
}


const handleToggleLotto = (value) => {
  if(value === 'buy') {
    setToggleValueLotto(value);
    fetchUserLottoDiscount();
    setShowBuyLotto(true);
    setShowClaimLotto(false);
    setShowHistoryLotto(false);
    setLottoSearchAmt();
    setLottoSearchFirst();
    setLottoSearchSecond();
    setLottoSearchThird();
    setLottoSearchFirstAmt();
    setLottoSearchSecondAmt();
    setLottoSearchThirdAmt();
 
  console.log(value + "buy")
};
if(value === 'claim') {
  setToggleValueLotto(value);
  setShowClaimLotto(true)
  fetchUserLottoFree()
  setShowBuyLotto(false);
  setShowHistoryLotto(false);
  setShowEthBuyLotto(false);
  setShowRevBuyLotto(false);
  setLottoSearchAmt();
  setLottoSearchFirst();
  setLottoSearchSecond();
  setLottoSearchThird();
  setLottoSearchFirstAmt();
  setLottoSearchSecondAmt();
  setLottoSearchThirdAmt();
  

  console.log(value + "claim")
};
if(value === 'history') {
  setToggleValueLotto(value);
  setShowHistoryLotto(true);
  setShowClaimLotto(false);
  setShowBuyLotto(false);
  setShowEthBuyLotto(false);
  setShowRevBuyLotto(false);


 
  console.log(value + "history")
};
};





const handleLottoToggle = (value) => {
  if(value === 'rev') {
    setShowRevBuyLotto(value);
    setShowEthBuyLotto(false);
    
    fetchUserLottoPrice()

  console.log(value + "rev")
};
if(value === 'eth') {
  setShowEthBuyLotto(value);
  setShowRevBuyLotto(false);
 
  fetchUserLottoPrice()


  console.log(value + "eth")
};

};










  const handleToggle = (value) => {
    if(value === 'stake') {
      setToggleValue(value);
      fetchUserBal()
      setShowStake(true)
      setShowTokenStake(false)
      setShowUnstake(false)
      setShowCompound(false)
    console.log(value + "Stake")
  };
  if(value === 'compound') {
    setToggleValue(value);
    setShowStake(false)
    setShowETHStake(false)
    setShowTokenStake(false)
    setShowUnstake(false)
    setShowCompound(true)
    setToggleStakeValue('');
    console.log(value + "")
  };
  if(value === 'unstake') {
    setToggleValue(value);
    setShowUnstake(true)
    setShowStake(false)
    setShowETHStake(false)
    setShowCompound(false)
    setShowTokenStake(false)
    setToggleStakeValue('');
    console.log(value + "unstake")
  };
};







const handleStakeToggle = (value) => {
  if(value === 'newstake') {
    setToggleStakeValue(value);
    setShowETHStake(true)
    setShowTokenStake(false)
  
    console.log(value + "Use ETH TO Stake")


  };
  if(value === 'usetokens') {
    setToggleStakeValue(value);
    setShowETHStake(false)
    setShowTokenStake(true)
 
    console.log(value + "Use Tokens TO Stake")

  };
};


     
  useEffect(() => {
    fetchLottoAmt()
    fetchStakerInfo()
    fetchLottoBought()
    fetchLottoData()
    fetchUserStakePercent()
    fetchTotalClaimed()
    fetchStakedPercent()
    fetchTotalLottoPaid()


}, [address])


//------------SET MINT FUNCTIONS HERE------------------




    const handleDepositChangeStakeETH = (e) => {
      setDepositChangeStakeETH(e.target.value);
    };

    const handleDepositChangeStakeETHSlip = (e) => {
      setDepositChangeStakeETHSlippage(e.target.value);
    };

    const handleDepositChangeUnstake = (e) => {
      setDepositChangeUnstake(e.target.value);
    };


    const handleDepositChangeStakeToken = (e) => {
      setDepositChangeStakeToken(e.target.value);
    };

    const handleDepositChangeCompoundAmt = (e) => {
      setDepositChangeCompoundETH(e.target.value);
    };

    const handleDepositChangeCompoundSlip = (e) => {
      setDepositChangeCompoundSlippage(e.target.value);
    };

    const handleDepositRevTicket = (e) => {
      setDepositChangeRevTicket(e.target.value);
    };

    const handleDepositETHTicket = (e) => {
      setDepositChangeETHTicket(e.target.value);
    };

    const handleLottoSearch = (e) => {
      setLottoSearch(e.target.value);
    };

    const handleWithdrawl = (e) => {
      setWithdrawlAmt(e.target.value);
    };





    async function fetchLottoData() {
      if (address){
        const ethersProvider = new BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()
        const contract = new Contract(nftContractAddress, LionNft.abi, signer);
        try {
          if (signer) {
            const tokensId = await contract.getUserTickets(signer);
            const ownerTokensId = tokensId.map((tokenId) => tokenId.toString());
            
            // Filter out tokens with ID 0
            const filteredTokensId = ownerTokensId.filter(tokenId => tokenId !== '0');
            
            setCampaignData(filteredTokensId);
          }
        } catch (err) {
          console.log(err.message);
        }
      } else {
        setCampaignData(["Not Connected"]);
      }
    }
    





    const withdrawlEth = async () => {
      try {
        const ethersProvider = new BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()
          const contract = new Contract(nftContractAddress, LionNft.abi, signer);
          let amt = parseUnits(withdrawlamt.toString(), 18);
        
          const transaction = await contract.claimEth(amt);
          Swal.fire({
            icon: 'info',
            title: 'Transaction Pending',
            text: 'Your Withdraw transaction is pending. Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });
        const receipt = await transaction.wait();
        console.log('Transaction successful:', receipt);
        Swal.close(); // Close the pending alert
        Swal.fire({
            icon: 'success',
            title: 'Transaction Successful',
            text: 'Withdraw Successful!',
        });

        fetchLottoAmt()
        fetchStakerInfo()
        fetchLottoBought()
        fetchLottoData()
        fetchUserStakePercent()
        fetchTotalClaimed()
    
          
      } catch (error) {
          console.error('Transaction failed:', error);
    
          Swal.fire({
              icon: 'error',
              title: 'Transaction Failed',
              text: error.reason || error.message,
          });
      }
    };


































 




    const RevTicketBuy = async () => {
      try {
        const ethersProvider = new BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()
          const contract = new Contract(nftContractAddress, LionNft.abi, signer);
        
          const transaction = await contract.revBuyTickets(RevTicketDeposit);
          Swal.fire({
            icon: 'info',
            title: 'Transaction Pending',
            text: 'Your RevShare Lotto Buy transaction is pending. Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });
        const receipt = await transaction.wait();
        console.log('Transaction successful:', receipt);
        Swal.close(); // Close the pending alert
        Swal.fire({
            icon: 'success',
            title: 'Transaction Successful',
            text: 'RevShare Lotto Buy Successful!',
        });


        fetchLottoBought()
        fetchLottoData()
        fetchLottoAmt()
        fetchStakerInfo()
    
          
      } catch (error) {
          console.error('Transaction failed:', error);
    
          Swal.fire({
              icon: 'error',
              title: 'Transaction Failed',
              text: error.reason || error.message,
          });
      }
    };




    const ETHTicketBuy = async () => {
      try {
          const ethersProvider = new BrowserProvider(walletProvider);
          const signer = await ethersProvider.getSigner();
          const contract = new Contract(nftContractAddress, LionNft.abi, signer);
  
          // Fetch price and discount as BigNumber objects
          const discount = await contract.discount(address);
          const price = await contract.baseTicketPrice();
  
          // Convert BigNumber objects to string
          const stringPrice = price.toString();
          console.log(stringPrice + " Price");
          const stringDiscount = discount.toString();
  
          let userPrice;
  
          // Check if discount is not 0
          if (stringDiscount !== "0") {
              // Calculate user price
              const amountDiscount = (parseFloat(stringPrice) * parseFloat(stringDiscount)) / 100;
              userPrice = (parseFloat(stringPrice) - amountDiscount) * EthTicketDeposit;
          } else {
              // If discount is 0, user price equals base price
              userPrice = stringPrice * EthTicketDeposit;
          }
  
          console.log(userPrice + " User Price");
  
          // Convert userPrice to Wei (assuming it's in Ether)
          const userPriceWei =userPrice.toString();
  
          // Initiate transaction to buy tickets
          const transaction = await contract.buyTickets(EthTicketDeposit, { value: userPriceWei });
          Swal.fire({
            icon: 'info',
            title: 'Transaction Pending',
            text: 'Your ETH Lotto Buy transaction is pending. Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });
        const receipt = await transaction.wait();
        console.log('Transaction successful:', receipt);
        Swal.close(); // Close the pending alert
        Swal.fire({
            icon: 'success',
            title: 'Transaction Successful',
            text: 'ETH Lotto Buy Successful!',
        });

        fetchLottoBought()
        fetchLottoData()
        fetchLottoAmt()
    
  
      } catch (error) {
          console.error('Transaction failed:', error);
  
          // Display error message
          Swal.fire({
              icon: 'error',
              title: 'Transaction Failed',
              text: error.reason || error.message,
          });
      }
  };
  



















    const tokenStake = async () => {
      try {
          const ethersProvider = new BrowserProvider(walletProvider);
          const signer = await ethersProvider.getSigner();
          const contract = new Contract(nftContractAddress, LionNft.abi, signer);
          const tokenContract = new Contract(shieldAddress, LionToken.abi, signer);
  
          const approvalAmt = await tokenContract.allowance(address, nftContractAddress);
          const divideApproval = approvalAmt;
          console.log(divideApproval + " Approval Amount");
          console.log(TokenStakeDeposit + " Lock Amount");
          const parse = parseUnits(TokenStakeDeposit.toString(), 18);
          console.log(parse + " Parsed Amount");
  
          if (divideApproval < parse) {
              const approveTransaction = await tokenContract.approve(nftContractAddress, parse);
              Swal.fire({
                  icon: 'info',
                  title: 'Transaction Pending',
                  text: 'Your approval transaction is pending. Please wait...',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  showConfirmButton: false,
              });
              await approveTransaction.wait();
              console.log('Allowance approval successful');
              Swal.close(); // Close the pending alert
          } else {
              console.log('No approval needed');
          }
  
          const transaction = await contract.stake(parse);
          Swal.fire({
              icon: 'info',
              title: 'Transaction Pending',
              text: 'Your stake transaction is pending. Please wait...',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false,
          });
          const receipt = await transaction.wait();
          console.log('Transaction successful:', receipt);
          Swal.close(); // Close the pending alert
          Swal.fire({
              icon: 'success',
              title: 'Transaction Successful',
              text: 'Stake Successful!',
          });
          fetchStakerInfo()
          fetchUserBal()
          fetchStakedPercent()
          fetchStakerInfo()
  
      } catch (error) {
          console.error('Transaction failed:', error);
  
          Swal.fire({
              icon: 'error',
              title: 'Transaction Failed',
              text: error.reason || error.message,
          });
      }
  };
  


  const ethStake = async () => {
    try {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(nftContractAddress, LionNft.abi, signer);
      
        const parse = parseUnits(ethStakeDeposit.toString(), 18);
        console.log(parse + "Parsed Amount");
      
        const transaction = await contract.newBuyToStake(parse, stakeETHSlippageDeposit, { value: parse });

        Swal.fire({
            icon: 'info',
            title: 'Transaction Pending',
            text: 'Your stake transaction is pending. Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });
        
        await transaction.wait();
        console.log('Transaction successful:', transaction);

        Swal.close(); // Close the pending alert

        Swal.fire({
            icon: 'success',
            title: 'Transaction Successful',
            text: 'Stake Successful!',
        });
        fetchStakerInfo()
        fetchUserBal()
        fetchStakedPercent()
        fetchStakerInfo()
        
    } catch (error) {
        console.error('Transaction failed:', error);

        Swal.fire({
            icon: 'error',
            title: 'Transaction Failed',
            text: error.reason || error.message,
        });
    }
};






const compound = async () => {
  try {
    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
      const contract = new Contract(nftContractAddress, LionNft.abi, signer);
    
      const parse = parseUnits(CompoundETHDeposit.toString(), 18);
      console.log(parse + "Parsed Amount")
    
      const transaction = await contract.revCompoundEth(parse,CompoundSlippageDeposit);
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Compound transaction is pending. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
    });
    
    await transaction.wait();
    console.log('Transaction successful:', transaction);

    Swal.close(); // Close the pending alert

    Swal.fire({
        icon: 'success',
        title: 'Transaction Successful',
        text: 'Compound Successful!',
    });
    fetchStakerInfo()
      
  } catch (error) {
      console.error('Transaction failed:', error);

      Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason || error.message,
      });
  }
};








const claimBonus = async () => {
  try {
    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
      const contract = new Contract(nftContractAddress, LionNft.abi, signer);
    
    
      const transaction = await contract.claimBonusTickets();
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Your Bonus Claim transaction is pending. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
    });
    
    await transaction.wait();
    console.log('Transaction successful:', transaction);

    Swal.close(); // Close the pending alert

    Swal.fire({
        icon: 'success',
        title: 'Transaction Successful',
        text: 'Tickets Claim Successful!',
    });
    fetchLottoData()
      
  } catch (error) {
      console.error('Transaction failed:', error);

      Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason || error.message,
      });
  }
};










const tokenUnstake = async () => {
  try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(nftContractAddress, LionNft.abi, signer);
      const tokenContract = new Contract(shieldStakeAddress, LionToken.abi, signer);

      const approvalAmt = await tokenContract.allowance(address, nftContractAddress);
      const divideApproval = approvalAmt;
      console.log(divideApproval + " Approval Amount");
      console.log(unstakeDeposit + " Unstake Amount");
      const parse = parseUnits(unstakeDeposit.toString(), 18);
      console.log(parse + " Parsed Amount");

      if (divideApproval < parse) {
          try {
              const approveTransaction = await tokenContract.approve(nftContractAddress, parse);
              Swal.fire({
                icon: 'info',
                title: 'Transaction Pending',
                text: 'Your approval transaction is pending. Please wait...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
            });
              await approveTransaction.wait();
              Swal.close(); // Close the pending alert
             
          } catch (approvalError) {
              console.error('Approval transaction failed:', approvalError);
              Swal.fire({
                  icon: 'error',
                  title: 'Approval Failed',
                  text: approvalError.reason || approvalError.message,
              });
          }
      } else {
          console.log('No approval needed');
      }

      const transaction = await contract.unStake(parse);

      Swal.fire({
          icon: 'info',
          title: 'Transaction Pending',
          text: 'Your unstake transaction is pending. Please wait...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
      });
    

      await transaction.wait();
      console.log('Transaction successful:', transaction);

      Swal.close(); // Close the pending alert

      Swal.fire({
          icon: 'success',
          title: 'Transaction Successful',
          text: 'Unstake Successful!',
      });
      fetchStakerInfo()
      fetchUserBal()
      fetchStakedPercent()

  } catch (error) {
      console.error('Transaction failed:', error);

      Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason || error.message,
      });
  }
};


  

    









 
  
  







       
       





return (

  <Box mb="100px">
            <Box>

                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">

                  

                    <Card className='Btns'    textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxthead'>
                        Chatter Shield’s Aegis dApp
                        
                        </CardHeader>

              

                        <div class="chatenai-separator">
                      <img class="w-100" src={topImg} alt=""></img> 
                      </div>

                       <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="20px">
                        <Card className='NFTHead'   textAlign="center"   borderRadius="30px"  fontSize="30px" fontWeight="bold">
                   
                        <CardHeader className='cardtxt'>
                          Shield User Information
                        
                        </CardHeader>

                        <div class="separator-animated mt--30 mb--30"></div>
                        <br></br>

                        <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="1px">
                        <Card className='NFTHead'   textAlign="center"   borderRadius="30px"  fontSize="30px" fontWeight="bold">
                        <Text className='cardtxt'>Claimed ETH</Text>
                        <Text className='cardtxt'>{totalClaimed}</Text>


                          </Card>


                          <Card className='NFTHead'   textAlign="center"   borderRadius="30px"  fontSize="30px" fontWeight="bold">
                         

                        <Text className='cardtxt'>Spendable ETH</Text>
                        <Text className='cardtxt'>{spendableeth}</Text>

                        <Popup
                              className='popupimage'
                              trigger={
                                <Button
                                  className='btnsubmit1'
                                  border='1px'
                                  borderRadius={30}
                                  variant='transparent'
                                  marginInline={2}
                                  fontSize="15px"
                                  colorScheme='blue'
                                  color='#E9D8FD'
                                 
                                  onClick={() => {
                                   
                                  }}
                                >
                                  <font><b>Withdraw ETH</b></font>
                                </Button>
                              }
                              modal
                              nested
                            >
                              {close => (
                                <div className="modal">
                                  <div className='headerMD'>
                                  
                                    <p className='cardtxt'>Withdraw</p>
                                    <div class="chatenai-separator">
                                      <img class="w-100" src={topImg} alt="" />
                                    </div>
                                  </div>
                                  <Flex justify="center">
                                    <Stack order={{ base: '2', md: '1' }} spacing="25px">
                                      <div className="form">

                                        <Text className='cardtxt'>Available Amount To Withdraw</Text>
                                        <Text className='cardtxt'>{spendableeth}</Text>
                                        <br></br>
                                        <p className='cardtxt'>Enter Amount To Withdraw</p>
                                         <br></br>
                                         <div className="toggle-container">
                                          <input
                                            id="project-ticker"
                                            type="text"
                                            className="form-control"
                                            onChange={handleWithdrawl}
                                            value={withdrawlamt}
                                            placeholder="0.1"
                                          />
                                       </div>
                                       <br></br>
                                       <div className="toggle-container">
                                         <button className={`toggle-button `} onClick={() => withdrawlEth()}>
                                           Withdraw
                                         </button>
                                         </div>
                                         <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>
                                      
                                       

                                        </div>
                                        <Button
                                        className='btnsubmit1'
                                        border='1px'
                                        borderRadius={30}
                                        variant='transparent'
                                        marginInline={2}
                                        fontSize="15px"
                                        colorScheme='blue'
                                        color='#E9D8FD'
                                        onClick={() => {
                                          // Reset state variables
                                          setWithdrawlAmt('');
                                     
                                          
                                          // Close the modal
                                          close();
                                        }}
                                      >
                                        <font><b>Close Window</b></font>
                                      </Button>
                                        </Stack>
                                        </Flex>
                                      
                                        </div>
                                        )}
                                        </Popup>
                     
                       
               

                          </Card>



                          <Card className='NFTHead'   textAlign="center"   borderRadius="30px"  fontSize="30px" fontWeight="bold">
                          <Text className='cardtxt'>Staking Earn Rate</Text>
                        <Text className='cardtxt'>{stakeRate}</Text>


                          </Card>


                      </Grid>
                     
                     </Card>
                     
                     </Grid>
                     <div class="chatenai-separator">
                      <img class="w-100" src={bottomImg} alt=""></img> 
                      </div>
                     











                     <br>
                        </br>
                      
                      

                    
                
                   

                    







                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">

                    <CardHeader className='cardtxthead'>
                    Utilities
                        
                        </CardHeader>
                    <div class="chatenai-separator">
                      <img class="w-100" src={topImg} alt=""></img> 
                      </div>
               
                    
                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="0px">
    
                      
                      


                      <Card className='NFTHead'   textAlign="center"   borderRadius="30px"  fontSize="30px" fontWeight="bold">
                      
                          <Text className='cardtxt'>Shield Staking</Text>
                         












                          <div class="chatenai-separator">
                      <img class="w-100" src={bottomImg} alt=""></img> 
                      </div>

                      <div class="chatenai-separator">
                      <img class="w-100" src={topImg} alt=""></img> 
                      </div>
                         
                        <Text className='cardtxt'>Your Staked Pool Percentage</Text>

 
                        <Text className='cardtxt'>{stakedPercent}</Text>
                        <div class="chatenai-separator">
                      <img class="w-100" src={bottomImg} alt=""></img> 
                      </div>
                      <br></br>

                       

                        <Popup
                              className='popupimage'
                              trigger={
                                <Button
                                  className='btnsubmit1'
                                  border='1px'
                                  borderRadius={30}
                                  variant='transparent'
                                  marginInline={2}
                                  fontSize="15px"
                                  colorScheme='blue'
                                  color='#E9D8FD'
                                  onClick={() => {
                                   
                                  }}
                                >
                                  <font><b>Shield Staking</b></font>
                                </Button>
                              }
                              modal
                              nested
                            >
                              {close => (
                                <div className="modal">
                                  <div className='headerMD'>
                                    <div class="chatenai-separator">
                                    <p className='cardtxt'>Information</p>
                                      <img class="w-100" src={topImg} alt="" />
                                    
                                    </div>
                                  </div>
                                  <div className='headerMD'></div>
                                  <Flex justify="center">
                                    <Stack order={{ base: '2', md: '1' }} spacing="5px">
                                      <div className="form">
                                        <Text className='cardtxt'>Your Staked Position</Text>
                                        <p className='cardtxt'>{stakedAmt}</p>

                                        <br />
                                        <p className='cardtxt'>Your Rate</p>
                                        <p className='cardtxt'>{stakeRate}</p>
                                       
                                        <br />
                                        <p className='cardtxt'>Compounded Tokens</p>
                                        <p className='cardtxt'>{compoundedtokens}</p>

                                        <br />
                                        <p className='cardtxt'>Unlock Time</p>
                                        <p className='cardtxt'>{stakeunlock}</p>
                                        <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>
                                       
                                     
                                    
                                      </div>
                                      
                                      <div class="chatenai-separator">
                                      <p className='cardtxt'>Select Interaction</p>
                                      <img class="w-100" src={topImg} alt="" />
                                    </div>

                                    {address && (
                                      <div className="toggle-container">
                                      <button className={`toggle-button ${toggleValue === 'stake' ? 'active' : ''}`} onClick={() => handleToggle('stake')}>
                                        Stake
                                      </button>
                                      <button className={`toggle-button ${toggleValue === 'compound' ? 'active' : ''}`} onClick={() => handleToggle('compound')}>
                                        Compound
                                      </button>
                                      <button className={`toggle-button ${toggleValue === 'unstake' ? 'active' : ''}`} onClick={() => handleToggle('unstake')}>
                                        Unstake
                                      </button>
                                    
                                    </div>
                                    )}

                                  {!address && (
                                      <div className="toggle-container">
                                <p className='cardtxt'>Connect Your Wallet To Begin</p>
                                    
                                    </div>
                                    )}




                                        <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>
                                      {showStake && (
                                       
                                       <div className="form">
                                           <p className='cardtxt'>Select Token</p>
                                         <div class="chatenai-separator">
                                        <img class="w-100" src={topImg} alt="" />
                                      </div>
                                   

                                      
                                         <br></br>
                                         <div className="toggle-container">
                                         <button className={`toggle-button ${toggleStakeValue === 'newstake' ? 'active' : ''}`} onClick={() => handleStakeToggle('newstake')}>
                                           Use ETH
                                         </button>
                                         <button className={`toggle-button ${toggleStakeValue === 'usetokens' ? 'active' : ''}`} onClick={() => handleStakeToggle('usetokens')}>
                                           Use SHIELD
                                         </button>
                                       

                                       
                                       
                                       </div>
                                       <br></br>
                                       <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>
                               
                                       </div>

                                  
                                    
                                      )}




                                    {showETHStake && (
                                       
                                       <div className="form">

                                         <div class="chatenai-separator">
                                         <p className='cardtxt'>Buy & Stake</p>
                                        <img class="w-100" src={topImg} alt="" />
                                      </div>
                                      <br></br>

                                         <p className='cardtxt'>Enter ETH Amount</p>
                                         <br></br>
                                         <div className="toggle-container">
                                          <input
                                            id="project-ticker"
                                            type="text"
                                            className="form-control"
                                            onChange={handleDepositChangeStakeETH}
                                            value={ethStakeDeposit}
                                            placeholder="0.1"
                                          />
                                       </div>
                                       <br></br>
                                          <p className='cardtxt'>Enter Slippage</p>
                                          <br></br>
                                          <div className="toggle-container">
                                        
                                          <br></br>

                                          <input
                                            id="project-ticker"
                                            type="text"
                                            className="form-control"
                                            onChange={handleDepositChangeStakeETHSlip}
                                            value={stakeETHSlippageDeposit}
                                            placeholder="5"
                                          />
                                       </div>
                                       <br></br>

                                       <div className="toggle-container">
                                         <button className={`toggle-button `} onClick={() => ethStake()}>
                                           Stake With ETH
                                         </button>
                                         </div>
                                         <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>
                               
                                        </div>
                                
                                    
                                      )}




                                      {showUnstake && (
                                       
                                       <div className="form">
                                         <div class="chatenai-separator">
                                         <p className='cardtxt'>Unstake</p>
                                        <img class="w-100" src={topImg} alt="" />
                                      </div>
                                      <br></br>

                                         <p className='cardtxt'>Enter SHIELD Amount</p>
                                         <br></br>
                                         <div className="toggle-container">
                                          <input
                                            id="project-ticker"
                                            type="text"
                                            className="form-control"
                                            onChange={handleDepositChangeUnstake}
                                            value={unstakeDeposit}
                                            placeholder="10000"
                                          />
                                       </div>
                                       <br></br>

                                       <div className="toggle-container">
                                         <button className={`toggle-button `} onClick={() => tokenUnstake()}>
                                           Unstake
                                         </button>
                                         </div>
                                         <br></br>
                                         <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>
                               
                                        </div>
                                
                                    
                                      )}



                                  {showTokenStake && (
                                       
                                       <div className="form">
                                         <div class="chatenai-separator">
                                         <p className='cardtxt'>Shield Stake</p>
                                        <img class="w-100" src={topImg} alt="" />
                                      </div>
                                      <br></br>
                                      <p className='cardtxt'>Your SHIELD Balance</p>
                                      <p className='cardtxt'>{userTokenBal}</p>
                                      <br></br>


                                         <p className='cardtxt'>Enter SHIELD Amount</p>
                                         <br></br>
                                         <div className="toggle-container">
                                          <input
                                            id="project-ticker"
                                            type="text"
                                            className="form-control"
                                            onChange={handleDepositChangeStakeToken}
                                            value={TokenStakeDeposit}
                                            placeholder="1000"
                                          />
                                       </div>
                                       <br></br>

                                       <div className="toggle-container">
                                         <button className={`toggle-button `} onClick={() => tokenStake('newstake')}>
                                           Stake With SHIELD
                                         </button>
                                         </div>
                                         <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>
                               
                                        </div>
                                
                                    
                                      )}

                                      {showCompound && (
                                       
                                       <div className="form">
                                         <div class="chatenai-separator">
                                         <p className='cardtxt'>Compound SHIELD</p>
                                        <img class="w-100" src={topImg} alt="" />
                                      </div>
                                      <br></br>
                                      <p className='cardtxt'>Your Spendable ETH</p>
                                      <p className='cardtxt'>{spendableeth}</p>
                                      <br></br>

                                         <p className='cardtxt'>Enter ETH Amount</p>
                                         <br></br>
                                         <div className="toggle-container">
                                          <input
                                            id="project-ticker"
                                            type="text"
                                            className="form-control"
                                            onChange={handleDepositChangeCompoundAmt}
                                            value={CompoundETHDeposit}
                                            placeholder="0.1"
                                          />
                                            </div>
                                          <br></br>
                                          <p className='cardtxt'>Enter Slippage</p>
                                          <br></br>
                                          <div className="toggle-container">
                                        
                                          <br></br>

                                          <input
                                            id="project-ticker"
                                            type="text"
                                            className="form-control"
                                            onChange={handleDepositChangeCompoundSlip}
                                            value={CompoundSlippageDeposit}
                                            placeholder="5"
                                          />
                                       </div>
                                       <br></br>

                                       <div className="toggle-container">
                                         <button className={`toggle-button `} onClick={() => compound()}>
                                           Compound Rewards
                                         </button>
                                         </div>
                                         <br></br>
                                         <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>
                                      <br></br>
                               
                                        </div>
                                
                                    
                                      )}







                                      <Button
                                        className='btnsubmit1'
                                        border='1px'
                                        borderRadius={30}
                                        variant='transparent'
                                        marginInline={2}
                                        fontSize="15px"
                                        colorScheme='blue'
                                        color='#E9D8FD'
                                        onClick={() => {
                                          // Reset state variables
                                          setToggleValue('');
                                          setToggleStakeValue('');
                                          setShowStake(false);
                                          setShowETHStake(false);
                                          setShowTokenStake(false);
                                          setShowCompound(false);
                                          setShowUnstake(false);
                                          // Close the modal
                                          close();
                                        }}
                                      >
                                        <font><b>Close Window</b></font>
                                      </Button>
                                    </Stack>
                                  </Flex>
                                </div>
                              )}
                            </Popup>
                          



                          <Popup
                              className='popupimage'
                              trigger={
                                <Button
                                  className='btnsubmit1'
                                  border='1px'
                                  borderRadius={30}
                                  variant='transparent'
                                  marginInline={2}
                                  fontSize="15px"
                                  colorScheme='blue'
                                  color='#E9D8FD'
                                  onClick={() => {
                                   
                                  }}
                                >
                                  <font><b>Staking Info</b></font>
                                </Button>
                              }
                              modal
                              nested
                            >
                              {close => (
                                <div className="modal">
                                  <div className='headerMD'>
                                  
                                    <p className='cardtxt'>Staking Information</p>
                                    <div class="chatenai-separator">
                                      <img class="w-100" src={topImg} alt="" />
                                    </div>
                                  </div>
                                  <Flex justify="center">
                                    <Stack order={{ base: '2', md: '1' }} spacing="25px">
                                      <div className="form">

                                        <Text className='cardtxt'> Minimum Tokens to stake = 50,000</Text>
                                        <br></br>
                                        <Text className='cardtxt'>45 day lock period</Text>
                                        <br></br>
                                        <Text className='cardtxt'>10% early withdraw fee</Text>
                                        <br></br>
                                        <Text className='cardtxt'>Adding to staked positions does not extend time</Text>
                                        <br></br>
                                        <Text className='cardtxt'>Unclaimed rewards from staking feed lotto pool</Text>
                                        <br></br>

                                        <Text className='cardtxt'>40% of revenue distributed every Sunday</Text>
                                        <br></br>
                                        <Text className='cardtxt'>4 Rewards Multipliers</Text>
                                        <br></br>
                                        <Text className='cardtxt'>0 NFTs = 50%</Text>
                                        <br></br>
                                        <Text className='cardtxt'>1 NFT = 80%</Text>
                                        <br></br>
                                        <Text className='cardtxt'>2 NFTs = 90%</Text>
                                        <br></br>
                                        <Text className='cardtxt'>3 NFTs = 100%</Text>
                                        <br></br>
                                        <Text className='cardtxt'>Can use ETH to purchase and stake shield Tax-Free</Text>
                                        <br></br>
                                        <Text className='cardtxt'>Rewards can be claimed, compounded, or used to buy Lotto Tickets</Text>






                                      
                                       </div>
                                  
                                       <div class="chatenai-separator">
                                      <img class="w-100" src={bottomImg} alt="" />
                                    </div>
                                      
                                        <Button
                                        className='btnsubmit1'
                                        border='1px'
                                        borderRadius={30}
                                        variant='transparent'
                                        marginInline={2}
                                        fontSize="15px"
                                        colorScheme='blue'
                                        color='#E9D8FD'
                                        onClick={() => {
                                          // Reset state variables
                                        
                                     
                                          
                                          // Close the modal
                                          close();
                                        }}
                                      >
                                        <font><b>Close Window</b></font>
                                      </Button>
                                        </Stack>
                                        </Flex>
                                      
                                        </div>
                                        )}
                                        </Popup>
                                     

                            

                       


                          </Card>
























                          <Card className='NFTHead'   textAlign="center"   borderRadius="30px"  fontSize="30px" fontWeight="bold">
                       
                          <Text className='cardtxt'>Shield Lotto</Text>

                          <div class="chatenai-separator">
                      <img class="w-100" src={bottomImg} alt=""></img> 
                      </div>

                   
                      <div class="chatenai-separator">
                      <img class="w-100" src={topImg} alt=""></img> 
                      </div>
                      <Text className='cardtxt'>Total ETH Paid</Text>
                            <Text className='cardtxt'>{totalLottoPaid}</Text>
                            <div class="chatenai-separator">
                      <img class="w-100" src={bottomImg} alt=""></img> 
                      </div>
                      <br></br>

                          
                            <Popup
                              className='popupimage'
                              trigger={
                                <Button
                                  className='btnsubmit1'
                                  border='1px'
                                  borderRadius={30}
                                  variant='transparent'
                                  marginInline={2}
                                  fontSize="15px"
                                  colorScheme='blue'
                                  color='#E9D8FD'
                                 
                                  onClick={() => {
                                   
                                  }}
                                >
                                  <font><b>Shield Lotto</b></font>
                                </Button>
                              }
                              modal
                              nested
                            >
                              {close => (
                                <div className="modal">
                                  <div className='headerMD'>
                                  
                                    <p className='cardtxt'>Lotto</p>
                                    <div class="chatenai-separator">
                                      <img class="w-100" src={topImg} alt="" />
                                    </div>
                                  </div>
                                  <Flex justify="center">
                                                      <Stack order={{ base: '2', md: '1' }} spacing="25px">
                                      <div className="form">
                                        <Text className='cardtxt'>Current Pool Amount</Text>
                                        <Text className='cardtxt'>{ShowTotalLottoAmt}</Text>
                                        <br></br>
                                        <Text className='cardtxt'>This Weeks Bought Tickets</Text>
                                        <Text className='cardtxt'>{showBoughtLotto}</Text>
                                     
                                        <br />
                                        <p className='cardtxt'>Your Ticket Numbers:</p>
                                        
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                        {campaignData.map((tokenId, index) => (
                                            <p className='cardtxt' key={index} style={{ margin: '5px' }}>{tokenId}</p>
                                        ))}
                                         </div>
                                                                          
                                    
                                        <div className='button-container'>
                                          {/* Add content for button-container */}
                                        </div>
                                      </div>
                                      <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>


                               
                                      <div class="chatenai-separator">
                                      <p className='cardtxt'>Select Interaction</p>
                                      <img class="w-100" src={topImg} alt="" />
                                    </div>

                                    {address && (


                                      <div className="toggle-container">
                                      <button className={`toggle-button ${toggleValueLotto === 'buy' ? 'active' : ''}`} onClick={() => handleToggleLotto('buy')}>
                                        Buy Tickets
                                      </button>
                                      <button className={`toggle-button ${toggleValueLotto === 'claim' ? 'active' : ''}`} onClick={() => handleToggleLotto('claim')}>
                                        Free Ticket Claim
                                      </button>
                                      <button className={`toggle-button ${toggleValueLotto === 'history' ? 'active' : ''}`} onClick={() => handleToggleLotto('history')}>
                                        Lotto History
                                      </button>
                                    
                                    </div>
                                    )}

                                    {!address && (
                                      <div className="toggle-container">
                                <p className='cardtxt'>Connect Your Wallet To Begin</p>
                                    
                                    </div>
                                    )}

  
  
                                    <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>



                                    {showBuyLotto && (



                                       
                                       <div className="form">



                                         <div class="chatenai-separator">
                                         <p className='cardtxt'>Buy Lotto</p>
                                        <img class="w-100" src={topImg} alt="" />
                                      </div>
                                      <br></br>


                                      <div className="toggle-container">
                                         <button className={`toggle-button ${showRevBuyLotto === 'rev' ? 'active' : ''}`} onClick={() => handleLottoToggle('rev')}>
                                           Use Rev Share
                                         </button>
                                         <button className={`toggle-button ${showEthBuyLotto === 'eth' ? 'active' : ''}`} onClick={() => handleLottoToggle('eth')}>
                                           Use ETH
                                         </button>
                                         </div>

                                         
                                         <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>
                                        </div>

                                        
                                
                                    
                                      )}









                                    {showRevBuyLotto && (



                                       
                                        <div className="form">
                                        <div class="chatenai-separator">
                                        <p className='cardtxt'>Buy With Rev Share</p>
                                        <img class="w-100" src={topImg} alt="" />
                                        </div>
                                        <br></br>
                                        <p className='cardtxt'>Your Spendable ETH</p>
                                        <p className='cardtxt'>{spendableeth}</p>
                                        <br></br>
                                        <p className='cardtxt'>Your Discount</p>
                                        <p className='cardtxt'>{lottoDiscount}%</p>
                                        <br></br>
                                        <p className='cardtxt'>Cost Per Ticket</p>
                                        <p className='cardtxt'>{userLottoPrice} ETH</p>
                                        <br></br>

                                        <p className='cardtxt'>Enter Ticket Amount</p>
                                        <br></br>
                                       
                                      
                                        <div className="toggle-container">

                                        <br></br>

                                        <input
                                          id="project-ticker"
                                          type="text"
                                          className="form-control"
                                          onChange={handleDepositRevTicket}
                                          value={RevTicketDeposit}
                                          placeholder="1"
                                        />
                                        </div>
                                        <br></br>

                                        <div className="toggle-container">
                                        <button className={`toggle-button `} onClick={() => RevTicketBuy()}>
                                        Buy With Rev Share
                                        </button>
                                        </div>
                                        <br></br>
                                        <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                        </div>
                                        <br></br>

                                        </div>


                                        )}





                                    {showEthBuyLotto && (



                                       
                                            <div className="form">
                                            <div class="chatenai-separator">
                                            <p className='cardtxt'>Buy With ETH</p>
                                            <img class="w-100" src={topImg} alt="" />
                                            </div>
                                            <br></br>
                                            <p className='cardtxt'>Your Discount</p>
                                            <p className='cardtxt'>{lottoDiscount}%</p>
                                            <br></br>
                                            <p className='cardtxt'>Cost Per Ticket</p>
                                            <p className='cardtxt'>{userLottoPrice} ETH</p>
                                            <br></br>

                                            <p className='cardtxt'>Enter Ticket Amount</p>
                                            <br></br>


                                            <div className="toggle-container">

                                            <br></br>

                                            <input
                                              id="project-ticker"
                                              type="text"
                                              className="form-control"
                                              onChange={handleDepositETHTicket}
                                              value={EthTicketDeposit}
                                              placeholder="1"
                                            />
                                            </div>
                                            <br></br>

                                            <div className="toggle-container">
                                            <button className={`toggle-button `} onClick={() => ETHTicketBuy()}>
                                              Buy With ETH
                                            </button>
                                            </div>
                                            <br></br>
                                            <div class="chatenai-separator">
                                            <img class="w-100" src={bottomImg} alt="" />
                                            </div>
                                            <br></br>

                                            </div>


                                            )}













                                {showClaimLotto && (
                                       
                                       <div className="form">

                                         <div class="chatenai-separator">
                                         <p className='cardtxt'>Free Lotto Claim</p>
                                        <img class="w-100" src={topImg} alt="" />
                                      </div>
                                      <br></br>
                                    
                                      <p className='cardtxt'>{userLottoFree}</p>

                                      <br></br>
                                       <div className="toggle-container">
                                {ShowFreeLottoBtn && (
                                         <button className={`toggle-button `} onClick={() => claimBonus()}>
                                           Claim Free Ticket
                                         </button>
                                       )}
                                         </div>
                                         <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>
                               
                                        </div>
                                
                                    
                                      )}




                                      







                            {showHistoryLotto && (
                                       
                                       <div className="form">

                                         <div class="chatenai-separator">
                                         <p className='cardtxt'>Lotto History</p>
                                        <img class="w-100" src={topImg} alt="" />
                                      </div>
                                      <br></br>

                                         <p className='cardtxt'>Enter Lotto Draw Number</p>
                                         <br></br>
                                         <div className="toggle-container">
                                          <input
                                            id="project-ticker"
                                            type="text"
                                            className="form-control"
                                            onChange={handleLottoSearch}
                                            value={LottoSearch}
                                            placeholder="1"
                                          />
                                       </div>
                                       <br></br>
                                      
                                         <p className='cardtxt'>{searchLottoAmt}</p>
                                         <br></br>
                                         <p className='cardtxt'>{searchLottoFirst}</p>
                                         <p className='cardtxt'fontSize='10px'>{searchLottoFirstAmt}</p>
                                         <br></br>
                                         <p className='cardtxt'>{searchLottoSecond}</p>
                                         <p className='cardtxt'>{searchLottoSecondAmt}</p>
                                         <br></br>
                                         <p className='cardtxt'>{searchLottoThird}</p>
                                         <p className='cardtxt'>{searchLottoThirdAmt}</p>
                               
                                       <br></br>

                                       <div className="toggle-container">
                                         <button className={`toggle-button `} onClick={() => fetchLottoSearch()}>
                                           View Draw
                                         </button>

                                     

                                         </div>
                                         <div class="chatenai-separator">
                                        <img class="w-100" src={bottomImg} alt="" />
                                      </div>
                               
                                        </div>
                                
                                    
                                      )}


                                      <Button
                                        className='btnsubmit1'
                                        border='1px'
                                        borderRadius={30}
                                        variant='transparent'
                                        marginInline={2}
                                        fontSize="15px"
                                        colorScheme='blue'
                                        color='#E9D8FD'
                                        onClick={() => {
                                          // Reset state variables
                                          setToggleValueLotto('');
                                          setShowClaimLotto(false);
                                          setShowHistoryLotto(false);
                                          setShowBuyLotto(false);
                                          setLottoSearchAmt();
                                          setLottoSearchFirst();
                                          setLottoSearchSecond();
                                          setLottoSearchThird();
                                          setLottoSearchFirstAmt();
                                          setLottoSearchSecondAmt();
                                          setLottoSearchThirdAmt();
                                          setShowEthBuyLotto(false);
                                          setShowRevBuyLotto(false);
                                          setLottoSearch('');
                                          
                                          // Close the modal
                                          close();
                                        }}
                                      >
                                        <font><b>Close Window</b></font>
                                      </Button>





















                                    </Stack>
                                  </Flex>
                                  
                                </div>
                              )}
                            </Popup>

                            <Popup
                              className='popupimage'
                              trigger={
                                <Button
                                  className='btnsubmit1'
                                  border='1px'
                                  borderRadius={30}
                                  variant='transparent'
                                  marginInline={2}
                                  fontSize="15px"
                                  colorScheme='blue'
                                  color='#E9D8FD'
                                  onClick={() => {
                                   
                                  }}
                                >
                                  <font><b>Lotto Info</b></font>
                                </Button>
                              }
                              modal
                              nested
                            >
                              {close => (
                                <div className="modal">
                                  <div className='headerMD'>
                                  
                                    <p className='cardtxt'>Lotto Information</p>
                                    <div class="chatenai-separator">
                                      <img class="w-100" src={topImg} alt="" />
                                    </div>
                                  </div>
                                  <Flex justify="center">
                                    <Stack order={{ base: '2', md: '1' }} spacing="25px">
                                      <div className="form">

                                        <Text className='cardtxt'>Bi-weekly Draw.</Text>
                                        <br></br>
                                        <Text className='cardtxt'>~$20 in eth per ticket</Text>
                                        <br></br>
                                        <Text className='cardtxt'>4 Ticket Prices</Text>
                                        <br></br>
                                        <Text className='cardtxt'>0 NFTs = full price</Text>
                                        <br></br>
                                        <Text className='cardtxt'>1 NFT = 10% Discount</Text>
                                        <br></br>
                                        <Text className='cardtxt'>2 NFTs = 20% Discount</Text>
                                        <br></br>
                                        <Text className='cardtxt'>3 NFTs = 30% Discount</Text>
                                        <br></br>
                                        <Text className='cardtxt'>Every 2 NFTs get a free ticket each draw</Text>
                                        <br></br>
                                        <Text className='cardtxt'>60% of Prize Pool distributed equally to 3 winners every draw</Text>
                                        <br></br>
                                        <Text className='cardtxt'>Unclaimed rewards from staking feed lotto pool</Text>
                                        <br></br>
                                        <Text className='cardtxt'>Tickets are only valid for the lotto period in which they were purchased</Text>
                                        <br></br>
                                        <Text className='cardtxt'>Winners with Staked Shield Tokens will have their ETH winning added to their account</Text>
                                        <br></br>
                                        <Text className='cardtxt'>Winners who are not staked will have their ETH sent to their wallet</Text>


                                      
                                      
                                       </div>
                                
                                       <div class="chatenai-separator">
                                      <img class="w-100" src={bottomImg} alt="" />
                                    </div>
                                      
                                        <Button
                                        className='btnsubmit1'
                                        border='1px'
                                        borderRadius={30}
                                        variant='transparent'
                                        marginInline={2}
                                        fontSize="15px"
                                        colorScheme='blue'
                                        color='#E9D8FD'
                                        onClick={() => {
                                          // Reset state variables
                                        
                                     
                                          
                                          // Close the modal
                                          close();
                                        }}
                                      >
                                        <font><b>Close Window</b></font>
                                      </Button>
                                        </Stack>
                                        </Flex>
                                      
                                        </div>
                                        )}
                                        </Popup>

                            
                                                    


                          </Card>


                          
                         
                              


                        

                    




















                    
                    </Grid>  

                  
                    </Grid>
                  
                   
           
                     
                  </Card>
                  </Grid>
              
             </Box>
            <br></br>
             <div class="chatenai-separator">
                      <img class="w-100" src={bottomImg} alt=""></img> 
                      </div>
        </Box>
        
    );
};
export default NFTHead;